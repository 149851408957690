<template>
  <div class="page">
    <el-row class="tool-bar">
      <el-col :span="12">
        <search-tool v-model="search" @input="$utils('search', getGrupeKorisnika)"/>
      </el-col>
      <el-col :span="12" align="right">
        <el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/grupe-korisnika/create')" v-if="$utils('roleUser', 'Grupe_korisnika', 'create')">{{ $t('global.novo') }}</el-button>
      </el-col>
    </el-row>
    <el-row>
      <!--Table-->
      <el-table v-if="grupeKorisnika.length"
                :data="grupeKorisnika"
                ref="table"
                :default-sort="sortBy"
                @sort-change="sort => $utils('changeSort', sort, getGrupeKorisnika)"
                v-loading="gridLoad"
                border
                stripe>
        <el-table-column
                align="left"
                prop="naziv"
                :label="$t('grupeKorisnika.naziv')"
                sortable="custom">
        </el-table-column>
        <el-table-column
                align="center"
                width="120">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Grupe_korisnika', 'edit')" v-show="scope.row.id > 2">
              <el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/grupe-korisnika/' + scope.row.id)" size="small"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Grupe_korisnika', 'delete')" v-show="scope.row.id > 2">
              <el-button type="danger" icon="fas fa-trash" @click="$delete('grupeKorisnika', scope.row.id)" size="small"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
    </el-row>
    <br>
    <el-row>
      <pagination v-model="pagination" @input="getGrupeKorisnika" ref="pagination"/>
    </el-row>
  </div>
</template>

<script>
export default {
	name: 'grupe-korisnika-list',
	data() {
		return {
			grupeKorisnika: [],
			pagination: {},
			gridLoad: false,
			sortBy: {
				prop: 'naziv',
				order: 'ascending'
			},
			search: ''
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('grupeKorisnika.grupeKorisnika')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getGrupeKorisnika()]);
	},
	methods: {
		getGrupeKorisnika() {
			this.gridLoad = true;
			return this.$get('grupeKorisnika', this.$utils('getParams')).then(data => {
				this.grupeKorisnika = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		}
	}
};
</script>
